import React, { useState } from 'react';
import './App.css';
import { Container } from 'react-bootstrap';
import UserActionApp from './UserCRUD/UserAction';
import NewsActionApp from './NewsCRUD/NewsAction';
import Chat from './AllChat/Chat';
import Navbar from './Navbar';
import Login from './Login/Login';
import useToken from './utils/useToken';
import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Users from './Users/Users';
import Flights from './Flights/Flights';
import PostedFlights from './PostedFlights/PostedFlights';
function App() {
  const {token, setToken}= useToken();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <Router>
    <Navbar />
    <Routes>
    
        <Route exact path='/'  element={<UserActionApp />} />
        <Route exact path='/users'  element={<Users/>} />
        <Route exact path='/flights'  element={<Flights/>} />
        <Route exact path='/posted-flights'  element={<PostedFlights/>} />
        <Route path='/coupons' element={<UserActionApp/>} />
        <Route path='/press' element={<NewsActionApp/>} />
        <Route path='/allchat' element={<Chat/>} />

    {/* <div className="App">
       <Container>
        
          <UserActionApp />
          
        </Container>
        
    </div> */}
    </Routes>
    </Router>
  );
}

export default App;
