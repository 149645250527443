import React from 'react';
import { Table,Button } from 'react-bootstrap';
import axios from 'axios';
//require('dotenv').config();
const apiUrl = process.env.REACT_APP_API_URL+"web/";

class UserList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
           error:null,
           coupons:[],
           response: {}
            
        }
    }

    componentDidMount(){
       axios.post(apiUrl + 'GetCoupons').then(response => response.data).then(
            (result)=>{
                this.setState({
                    coupons:result
                });
            },
            (error)=>{
                this.setState({error});
            }
        )
    }

    
    deleteUser(CouponId) {
      const { coupons } = this.state;   
     axios.post(apiUrl + 'DeleteCoupon/' , {CouponId : CouponId}).then(result=>{
       alert("Coupon Deleted.");
        this.setState({
          response:result,
          coupons:coupons.filter(coupon=>coupon.id !== CouponId)
        });
      });
    }

    render(){       
        const{error,coupons}=this.state;
        if(error){
            return(
                <div>Error:{error.message}</div>
            )
        }
        else
        {
            return(
         <div>
                    
                  <Table>
                    <thead className="btn-primary">
                      <tr>
                        <th>Coupon Id</th>
                        <th>Coupon Code</th>
                        <th>Trial Days</th>
                        <th>Coupon Details</th>                      
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupons.map(coupon => (
                        <tr key={coupon.id}>
                          <td>{coupon.id}</td>
                          <td>{coupon.coupon_code}</td>
                          <td>{coupon.trial_days}</td>
                          <td>{coupon.coupon_details}</td>                          
                          <td><Button variant="info" onClick={() => this.props.editUser(coupon.id)}>Edit</Button>  &nbsp;&nbsp;&nbsp;
                          <Button variant="danger" onClick={() => this.deleteUser(coupon.id)}>Delete</Button>
                        
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )
        }
    }
}

export default UserList;