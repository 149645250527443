import React from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';


class AddUser extends React.Component {
  constructor(props) {
    super(props);
 
    this.initialState = {
      id: '',
      CouponDetails: '',
      TrialDays: '',
      CouponCode: '',
      MobileNo: '',
      Address: '',
      PinCode: '',
    }

    if (props.user.length>0) {    
      console.log(this.props.user[0]);        
      this.state = this.props.user[0];
      console.log(this.state.id);
    } else {
      console.log(this.props);
      this.state = this.initialState;
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    //this.state = this.initialState;
    this.setState(this.initialState);
    this.props.onFormSubmit(this.state);
    this.state = this.initialState;
    this.props = this.state;
    console.log(this.state);
    //window.location.reload();
    
  }
  render() {
    let pageTitle;
    let actionStatus;
    if (this.state.id) {
      console.log(this.state);
      pageTitle = <h1>Edit Coupon</h1>
      actionStatus = <b>Update</b>
    } else {
      console.log(this.state);
      pageTitle = <h2>Add Coupon</h2>
      actionStatus = <b>Save</b>
    }

    return (
      <div>      
         {pageTitle}
        <Row>
          <Col sm={7}>
            <Form onSubmit={this.handleSubmit}>
              
              <Form.Group controlId="coupon_code">
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control
                  type="text"
                  name="coupon_code"
                  value={this.state.coupon_code}
                  onChange={this.handleChange}
                  placeholder="Coupon Code" />
              </Form.Group>
              <Form.Group controlId="trial_days">
                <Form.Label>TrialDays</Form.Label>
                <Form.Control
                  type="text"
                  name="trial_days"
                  value={this.state.trial_days}
                  onChange={this.handleChange}
                  placeholder="TrialDays" />
              </Form.Group>
              <Form.Group controlId="coupon_details">
                <Form.Label>Coupon Details</Form.Label>
                <Form.Control
                  type="text"
                  name="coupon_details"
                  value={this.state.coupon_details}
                  onChange={this.handleChange}
                  placeholder="CouponDetails" />
              </Form.Group>
                            
              <Form.Group>
                <Form.Control type="hidden" name="CouponId" value={this.state.id} />
                <Button variant="success" type="submit">{actionStatus}</Button>          

              </Form.Group>
            </Form>
          </Col>
        </Row>
      </div>
      
    )
  }
}

export default AddUser;