import { useState, useEffect } from "react";
import * as React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Form, Col, Button } from "react-bootstrap";
// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

class AddPress extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      id: "",
      cover_image: "",
      headline: "",
      link: "",
      original_date: "",
      date: new Date(),
    };

    if (props.press.length > 0) {
      console.log(this.props.press[0]);
      this.state = this.props.press[0];
      console.log(this.state.id);
    } else {
      console.log(this.props);
      this.state = this.initialState;
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  }

  setStartDate(date) {
    this.setState({
      ["date"]: date.toLocaleDateString(),
    });
    console.log("Changed: " + JSON.stringify(this.state));
  }

  handleSubmit(event) {
    event.preventDefault();
    //this.state = this.initialState;
    this.setState(this.initialState);
    this.props.onFormSubmit(this.state);
    this.state = this.initialState;
    this.props = this.state;
    console.log(this.state);
    //window.location.reload();
  }

  render() {
    let pageTitle;
    let actionStatus;
    if (this.state.id) {
      //this.state.date = this.state.original_date;
      console.log(this.state);
      pageTitle = <h1>Edit Press</h1>;
      actionStatus = <b>Update</b>;
    } else {
      console.log(this.state);
      pageTitle = <h2>Add Press</h2>;
      actionStatus = <b>Save</b>;
    }

    return (
      <div>
        {pageTitle}
        <Row>
          <Col sm={7}>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="headline">
                <Form.Label>Headline</Form.Label>
                <Form.Control
                  type="text"
                  name="headline"
                  value={this.state.headline}
                  onChange={this.handleChange}
                  placeholder="Headline"
                />
              </Form.Group>
              <Form.Group controlId="cover_image">
                <Form.Label>Image Link</Form.Label>
                <Form.Control
                  type="text"
                  name="cover_image"
                  value={this.state.cover_image}
                  onChange={this.handleChange}
                  placeholder="Cover Image link"
                />
              </Form.Group>
              <Form.Group controlId="link">
                <Form.Label>Press Link</Form.Label>
                <Form.Control
                  type="text"
                  name="link"
                  value={this.state.link}
                  onChange={this.handleChange}
                  placeholder="Link to press"
                />
              </Form.Group>
              <Form.Group controlId="date">
                <Form.Label>Date</Form.Label>
                {/* <Form.Control
                  type="text"
                  name="date"
                  value={this.state.date}
                  onChange={this.handleChange}
                  placeholder="Link to press" /> */}
                <DatePicker
                  showIcon
                  selected={new Date(this.state.date)}
                  name="date"
                  value={this.state.date}
                  //selected={ (this.state.id) ? new Date( Date.parse(this.state.original_date)) : new Date()  }
                  onChange={(date) => this.setStartDate(date)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  type="hidden"
                  name="PressId"
                  value={this.state.id}
                />
                <Button variant="success" type="submit">
                  {actionStatus}
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AddPress;
