import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';

//  return fetch('http://localhost:8080/login', {
//    method: 'POST',
//    headers: {
//      'Content-Type': 'application/json'
//    },
//    body: JSON.stringify(credentials)
//  })
//    .then(data => data.json())


export default function Login({ setToken }) {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [isInvalid, setIsInvalid] = useState(false);

  async function loginUser(credentials) {
    if(credentials.username == process.env.REACT_APP_USERNAME && credentials.password == process.env.REACT_APP_PASSWORD)
    {
        return { token: process.env.REACT_APP_AUTH_TOKEN}
    }
    else{
        setIsInvalid(true);
        //return { token: ''}
    }
}
  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });
    setToken(token);
  }

  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={e => setUserName(e.target.value)} />
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)} />
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
        <div>
            { isInvalid && <p>Invalid credentials</p>}
        </div>
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};