import React, { Component } from 'react';

import { Container, Button } from 'react-bootstrap';
import UserList from './GetUser';
import AddUser from './AddUser';
import axios from 'axios';
//require('dotenv').config();
console.log(process.env.REACT_APP_API_URL);
const apiUrl = process.env.REACT_APP_API_URL+"web/";

class UserActionApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddUser: false,
      error: null,
      response: {},
      userData: {},
      isEditUser: false,
      isUserDetails:true,
    }

    this.onFormSubmit = this.onFormSubmit.bind(this);

  }

  onCreate() {
    this.setState({ isAddUser: true });
    this.setState({ isUserDetails: false });
  }
  onDetails() {
    this.setState({ isUserDetails: true });
    this.setState({ isAddUser: false });
  }

  onFormSubmit(data) {
    this.setState({ isAddUser: true });
    this.setState({ isUserDetails: false });
    if (this.state.isEdituser) {
     axios.post(apiUrl + 'UpdateCoupon',data).then(result => {
      alert("Coupon updated successfully.");
      window.location.reload();
        this.setState({
          response:result,  
          isAddUser: false,
          isEdituser: false
        })
      });
    } else {
   
     axios.post(apiUrl + 'InsertCoupon',data).then(result => {
      alert("Coupon Added Successfully.");        
        this.setState({
          response:result,  
          isAddUser: false,
          isEdituser: false
        })
      });
    }
  
  }

  editUser = id => {

    this.setState({ isUserDetails: false });
    axios.post(apiUrl + "GetCouponById/", {CouponId: id}).then(result => {

        this.setState({
          isEdituser: true,
          isAddUser: true,
          userData: result.data         
        });
      },
      (error) => {
        this.setState({ error });
      }
    )
   
  }


  render() {
  
    let userForm;
    if (this.state.isAddUser || this.state.isEditUser) {

      userForm = <AddUser onFormSubmit={this.onFormSubmit} user={this.state.userData} />
     
    }
  

    return (
      <div className="App">
 <Container>
        <h1 style={{ textAlign: 'center' }}>KATANA COUPONS</h1>
        <hr></hr>
        {!this.state.isUserDetails && <Button variant="primary" onClick={() => this.onDetails()}> Coupon Details</Button>}
        {!this.state.isAddUser && <Button variant="primary" onClick={() => this.onCreate()}>Add Coupon</Button>}
        <br></br>
        {!this.state.isAddUser && <UserList editUser={this.editUser} />}
        {userForm}
        </Container>
      </div>
    );
  }
}
export default UserActionApp;