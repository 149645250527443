import React, { Component } from "react";

import { Container, Button } from "react-bootstrap";
import PressList from "./GetPress";
import AddPress from "./AddPress";
import axios from "axios";
//require('dotenv').config();
//const apiUrl = 'https://pay.flykatana.com/api/web/';
const apiUrl = process.env.REACT_APP_API_URL + "web/";

class NewsActionApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddPress: false,
      error: null,
      response: {},
      pressData: {},
      isEditPress: false,
      isPressDetails: true,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onCreate() {
    this.setState({ isAddPress: true });
    this.setState({ isPressDetails: false });
  }
  onDetails() {
    this.setState({ isPressDetails: true });
    this.setState({ isAddPress: false });
  }

  onFormSubmit(data) {
    this.setState({ isAddPress: true });
    this.setState({ isPressDetails: false });
    if (this.state.isEditPress) {
      axios.post(apiUrl + "UpdatePress", data).then((result) => {
        alert("Press updated successfully.");
        window.location.reload();
        this.setState({
          response: result,
          isAddPress: false,
          isEditPress: false,
        });
      });
    } else {
      axios.post(apiUrl + "AddPress", data).then((result) => {
        alert("Press Added Successfully.");
        this.setState({
          response: result,
          isAddPress: false,
          isEditPress: false,
        });
      });
    }
  }

  editPress = (id) => {
    this.setState({ isPressDetails: false });
    axios.post(apiUrl + "GetPressById/", { PressId: id }).then(
      (result) => {
        this.setState({
          isEditPress: true,
          isAddPress: true,
          pressData: result.data,
        });
      },
      (error) => {
        this.setState({ error });
      }
    );
  };

  render() {
    let pressForm;
    if (this.state.isAddPress) {
      pressForm = (
        <AddPress
          onFormSubmit={this.onFormSubmit}
          press={this.state.pressData}
          action="add"
        />
      );
    }

    if (this.state.isEditPress) {
      pressForm = (
        <AddPress
          onFormSubmit={this.onFormSubmit}
          press={this.state.pressData}
          action="edit"
        />
      );
    }

    return (
      <div className="PressApp">
        <Container style={{overflowX:"auto"}}>
          <h1 style={{ textAlign: "center" }}>KATANA PRESS</h1>
          <hr></hr>
          {!this.state.isPressDetails && (
            <Button variant="primary" onClick={() => this.onDetails()}>
              {" "}
              Press Details
            </Button>
          )}
          {!this.state.isAddPress && (
            <Button variant="primary" onClick={() => this.onCreate()}>
              Add Press
            </Button>
          )}
          <br></br>
          {!this.state.isAddPress && <PressList editPress={this.editPress} />}
          {pressForm}
        </Container>
      </div>
    );
  }
}
export default NewsActionApp;
