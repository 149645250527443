import React from "react";
import { Table, Button } from "react-bootstrap";
import axios from "axios";
//require('dotenv').config();

//const apiUrl = 'https://pay.flykatana.com/api/web';
const apiUrl = process.env.REACT_APP_API_URL + "web/";

class PressList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      press_list: [],
      response: {},
    };
  }

  componentDidMount() {
    axios
      .get(apiUrl + "GetPress")
      .then((response) => response.data)
      .then(
        (result) => {
          this.setState({
            press_list: result,
          });
        },
        (error) => {
          this.setState({ error });
        }
      );
  }

  deletePress(PressId) {
    const { press_list } = this.state;
    axios.post(apiUrl + "DeletePress/", { PressId: PressId }).then((result) => {
      alert("Press Deleted.");
      this.setState({
        response: result,
        press_list: press_list.filter((press) => press.id !== PressId),
      });
    });
  }

  render() {
    const { error, press_list } = this.state;
    if (error) {
      return <div>Error:{error.message}</div>;
    } else {
      return (
        <div>
          <Table>
            <thead className="btn-primary">
              <tr>
                <th style={{whiteSpace:"nowrap"}}>Press Id</th>
                <th>Headline</th>
                <th>Press Link</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {press_list.map((press) => (
                <tr key={press.id}>
                  <td style={{whiteSpace:"nowrap"}}>{press.id}</td>
                  <td style={{whiteSpace:"nowrap"}}>{press.headline}</td>
                  <td>{press.link}</td>
                  <td style={{whiteSpace:"nowrap"}}>{press.date}</td>
                  <td style={{whiteSpace:"nowrap"}}>
                    <Button
                      variant="info"
                      onClick={() => this.props.editPress(press.id)}
                    >
                      Edit
                    </Button>{" "}
                    &nbsp;&nbsp;&nbsp;
                    <Button
                      variant="danger"
                      onClick={() => this.deletePress(press.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    }
  }
}

export default PressList;
