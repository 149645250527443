import dummyUser from "./Image/login.png";
import "antd/dist/antd.css";
import "./Chat.css";
import { Input, Layout, Badge } from "antd";
import { MdOutlineSearch, MdArrowBack } from "react-icons/md";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { truncString } from "./utils/stringMethods";
import { dateDisplay } from "./utils/dateConverter";
import { RiCheckDoubleFill } from "react-icons/ri";
const { Header, Sider, Content } = Layout;
const Chat = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [value, setValue] = useState("");
  const [userChatData, setUserChatData] = useState([]);
  const [allChatMessage, setAllChatMessage] = useState([]);
  const [activeConversation_id, setActiveConversation_id] = useState(null);
  const [user1_name_pic, seUser1_name_pic] = useState({
    profilePicPath: "",
    userName: "",
    user1_id: null,
  });
  const [user2_name_pic, setUser2_name_pic] = useState({
    profilePicPath: "",
    userName: "",
  });
  const messagesRef = useRef(null);

  // api call for all list of conversation
  const CallListConversationsAdmin = async () => {
    try {
      const ListOfuser = await axios.get(
        process.env.REACT_APP_API_URL + `listConversationsAdmin`
      );
      setUserChatData(ListOfuser.data.data);
      //GetAllListOfMessage(ListOfuser.data.data[0]);
    } catch (error) {
      console.error(error);
    }
  };

  // Api call for all chat message

  const GetAllListOfMessage = async (data) => {
    setCollapsed(true);
    const conversation_id = data.conversation_id;
    setActiveConversation_id(conversation_id);
    try {
      const AllMessage = await axios.get(
        process.env.REACT_APP_API_URL + `listMessagesAdmin/${conversation_id}`
      );
      setAllChatMessage(AllMessage.data.data);
    } catch (error) {
      console.error(error);
    }
    seUser1_name_pic({
      profilePicPath: data.user1_profile_pic_path,
      userName: data.user1_name,
      user1_id: data.user1_id,
    });
    setUser2_name_pic({
      profilePicPath: data.user2_profile_pic_path,
      userName: data.user2_name,
    });
  };

  //  redirect to chat of user
  const filterUserChatData = (item, filterValue) => {
    const normalizedFilter = filterValue.toLowerCase();
    return (
      item.user_name?.toLowerCase().includes(normalizedFilter) ||
      item.last_message?.toLowerCase().includes(normalizedFilter)
    );
  };

  const searchData = userChatData.filter((item) =>
    filterUserChatData(item, value)
  );
  //call fetchdata

  useEffect(() => {
    CallListConversationsAdmin();
  }, []);

  // scroll message

  useEffect(() => {
    scrollToBottom();
  }, [allChatMessage]);

  const scrollToBottom = () => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  };

  return (
    <Layout className="chat-container">
      <Sider
        // className={` ${collapsed ? "false-all-caht":"chat-aside" }`}
        className={`chat-aside  ${collapsed ? "false-all-caht" : ""}`}
        trigger={null}
        collapsible
        // collapsed={collapsed}
        style={{
          background: "rgb(243 243 243)",
          transition: "width 0.5s ease-in-out",
        }}
        width={350}
      >
        <div className="chat-search text-center">
          <Input
            size="large"
            placeholder="Search user or start a chat..."
            prefix={<MdOutlineSearch />}
            style={{ padding: "10px" }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="all-chat">
          {searchData.length > 0 ? (
            searchData.map((data, index) => (
              <div
                className={`user ${
                  activeConversation_id === data.conversation_id && "active"
                }`}
                onClick={() => GetAllListOfMessage(data)}
                key={index}
              >
                <div style={{ width: "30%" }} className="dummyUser user1-data">
                  <img
                    src={
                      data.user1_profile_pic_path
                        ? data.user1_profile_pic_path
                        : dummyUser
                    }
                    alt="img"
                  />
                  <p className="chat-username">{data.user1_name}</p>
                </div>
                <div style={{ width: "40%" }} className="para">
                  <p>{truncString(data.last_message, 13)}</p>
                  {data.unread_count > 0 && (
                    <Badge
                      count={data.unread_count}
                      style={{ background: "green" }}
                    />
                  )}
                  <p className="date">{dateDisplay(data.last_message_time)}</p>
                </div>
                <div style={{ width: "30%" }} className="dummyUser user2-data">
                  <img
                    src={
                      data.user2_profile_pic_path
                        ? data.user2_profile_pic_path
                        : dummyUser
                    }
                    alt="img"
                  />
                  <p className="chat-username">{data.user2_name}</p>
                </div>
              </div>
            ))
          ) : (
            <span className="not-found ">No Chat !!</span>
          )}
        </div>
      </Sider>

      <Layout
        className={`site-layout ${
          collapsed ? "true-collapesd" : "false-collapesd"
        } `}
      >
        <Header
          className="site-layout-background"
          style={{
            background: "white",
            borderBottom: "1px solid lightgray",
          }}
        >
          {window.innerWidth < 650 && (
            <div className={"trigger"} onClick={() => setCollapsed(!collapsed)}>
              {/* <ToggleButton /> */}
              {collapsed && <MdArrowBack />}
            </div>
          )}
          <div className="chat-user-detail">
            <div className="Side-img-name">
              <img
                className="single-chat-user"
                src={
                  user1_name_pic.profilePicPath
                    ? user1_name_pic.profilePicPath
                    : dummyUser
                }
                alt="img"
              />
              <p className="single-chat-phone">{user1_name_pic.userName}</p>
            </div>
            <div className="Side-img-name">
              <img
                className="single-chat-user"
                src={
                  user2_name_pic.profilePicPath
                    ? user2_name_pic.profilePicPath
                    : dummyUser
                }
                alt="img"
              />
              <p className="single-chat-phone">{user2_name_pic.userName}</p>
            </div>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            // padding: 20,
            minHeight: 280,
            backgroundColor: "white",
            borderRadius: "5px",
          }}
        >
          <div ref={messagesRef} className="chat-data-here all-chat">
            {allChatMessage.length == 0 ? (
              <div className="empty-chat">{/* welcome to chat */}</div>
            ) : (
              allChatMessage.map((data, index) => (
                <div
                  key={index}
                  className={`message ${
                    data.sender_id !== user1_name_pic.user1_id
                      ? "sent"
                      : "received"
                  }`}
                >
                  <div className="message-content">
                    {data.message_text}
                    <div className="TimeandCheck">
                      <span className="timestamp">
                        {dateDisplay(data.timestamp)}
                      </span>
                      <span
                        className={`timestamp ${
                          data.is_read && "message-read"
                        }`}
                      >
                        {" "}
                        <RiCheckDoubleFill />
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default Chat;
