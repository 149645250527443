import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space, Table, Tag } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

const Users = () => {
  const [userData, setUserData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    getUserData();
  }, []);
  const renderSubscriptionStatus = (endDate) => {
    const currentDate = new Date();
    const endDateTime = new Date(endDate);
    
    if (currentDate <= endDateTime) {
      return <Tag color="#376f20">{endDate}</Tag>;
      } else {
      return <Tag color="#ff0014">{endDate}</Tag>;
    }
  };
  const getUserData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}`);
      const data = response.data;
      setUserData(data);
      if (data.length > 0) {
        const dynamicColumns = generateColumns(data[0]);
        setColumns(dynamicColumns);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const generateColumns = (dataItem) => {
    return Object.keys(dataItem).map((key) => ({
      title: key,
      dataIndex: key,
      key: key,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#007bff",
          color: "white",
        },
      }),
      ...getColumnSearchProps(key),
      render: (text, record) => {
        let content = text;
        content = (
          <span style={{ whiteSpace: "nowrap" }}>
            {key === "subscription_plan_id" ? (
              <Tag
                color={
                  text == "1" ? "#ff0014" : text == "3" ? "#376f20" : "#007bff"
                }
              >
                {text}
              </Tag>
            ) : key === "subscription_end" ? (
              renderSubscriptionStatus(text)    
            ) : (
              text
            )}
          </span>
        );
        return content;
      },
    }));
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ fontSize: "17px" }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  return (
    <div>
      <h3 style={{ textAlign: "center" }}>Users data</h3>
      <Table
        style={{ marginTop: "20px" }}
        columns={columns}
        dataSource={userData}
        rowKey="id"
        scroll={{ x: "100vw" }}
      />
      ;
    </div>
  );
};

export default Users;
