import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import styles from "./NavBarElements.module.css";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [menubar, setMenubar] = useState(false);
  const location = useLocation();
  const handleLogoutButton = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleNavHamburger = () => {
    setMenubar(!menubar);
  };

  return (
    <>
      <nav className={styles.nav}>
        <div className={styles.navMenu}>
          <a
            href="/users"
            className={`${styles.navLink} ${
              location.pathname === "/users" && styles.active
            }`}
          >
            Users
          </a>
          <a
            href="/posted-flights"
            className={`${styles.navLink} ${
              location.pathname === "/posted-flights" && styles.active
            }`}
          >
            Posted Flights
          </a>
          <a
            href="/flights"
            className={`${styles.navLink} ${
              location.pathname === "/flights" && styles.active
            }`}
          >
            Flights
          </a>
          <a
            href="/coupons"
            className={`${styles.navLink} ${
              location.pathname === "/coupons" && styles.active
            }`}
          >
            Coupons
          </a>
          <a
            href="/press"
            className={`${styles.navLink} ${
              location.pathname === "/press" && styles.active
            }`}
          >
            Press
          </a>
          <a
            href="/allchat"
            className={`${styles.navLink} ${
              location.pathname === "/allchat" && styles.active
            }`}
          >
            Chat Logs
          </a>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <button
            onClick={handleLogoutButton}
            className={`fs-5 border-0 bg-transparent ${styles.navLink}`}
          >
            Log Out
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
